<template>
  <div>
    <!-- Product list table ued zaaval product-table gesen class uguh -->
    <el-table
      v-loading="loading"
      :data="orders"
      style="width: 100%"
      @cell-click="clickCell"
      ref="elTable"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.OrderItems">
            <el-table-column label="Зураг">
              <template slot-scope="scope">
                <el-image style="width: 80px; height: 80px" fit="fill" :src="Array.isArray(scope.row.image) ? scope.row.image[0] : scope.row.image"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="Брэнд" prop="brand_name"></el-table-column>
            <el-table-column label="Бүтээгдэхүүн" prop="product_name"></el-table-column>
            <el-table-column label="Хувилбар /төрөл/">
              <template slot-scope="scope">
                <span>{{scope.row.variant_name === 'default' ? 'Хувилбаргүй' : scope.row.variant_name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Нийлүүлэгчийн SKU" prop="supplier_sku"></el-table-column>
            <el-table-column label="Тоо ширхэг" prop="item_count">
              <template slot-scope="scope">
                <span>{{ parseFloat(scope.row.item_count) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Нэгж үнэ" prop="menu_price">
              <template slot-scope="scope">
                <span>{{ generateCurrency(scope.row.menu_price) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="Захиалгын дугаар" prop="order_number" width="150px">
        <template slot-scope="scope">
          <strong>{{scope.row.order_number}}</strong>
        </template>
      </el-table-column>
      <el-table-column label="Захиалгын төлөв" width="200">
        <template slot-scope="scope">
          <el-tag :type="scope.row.order_status === 'Цуцалсан' ? 'danger' : scope.row.order_status === 'Амжилттай' ? 'success' : 'warning'">{{scope.row.order_status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Төлбөрийн төлөв" width="150px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.pay_status === 'Төлөөгүй' ? 'danger' : 'success'">{{ scope.row.pay_status }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="brand_name" label="Брэнд" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.OrderItems[0].brand_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Барааны нэр" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.OrderItems[0].product_name }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="Харилцагч SKU" width="200">
        <template slot-scope="scope">
          {{ scope.row.OrderItems[0].supplier_sku }}
        </template>
    </el-table-column>
      <!-- <el-table-column label="Нэгж үнэ" width="100">
        <template slot-scope="scope">
          {{ generateCurrency(scope.row.OrderItems[0].menu_price) }}
        </template>
      </el-table-column>
      <el-table-column label="Тоо ширхэг" width="100">
        <template slot-scope="scope">
          {{ parseFloat(scope.row.OrderItems[0].item_count) }} ш
        </template>
      </el-table-column> -->
      <el-table-column label="Нийт үнэ" width="150">
        <template slot-scope="scope">
          {{ generateCurrency(scope.row.menu_total) }}
        </template>
      </el-table-column>
      <el-table-column label="Бараа олгох огноо"  width="200px">
        <div slot-scope="scope">
         <span> {{(scope.row.promised_time)}}</span>
        </div>
      </el-table-column>
      <el-table-column label="Захиалсан огноо" width="150">
        <template slot-scope="scope">
          {{scope.row.createdAt}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'orderTable',
  props: {
    orders: {
      type: Array
    },
    pageSize: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number,
      default: 0
    },
    curentPageHandler: {
      type: Function
    },
    sizeChangeHandler: {
      type: Function
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isloading: false
    }
  },
  methods: {
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },

    clickCell (row) {
      this.$refs.elTable.toggleRowExpansion(row)
    }
  }
}
</script>
