<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Захиалгын жагсаалт</h2>
        </el-col>
        <el-col :span="12">
          <div class="text-right">
            <el-button type="info" @click="dialogTableVisible = true">Тайлбар</el-button>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
        <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" @input="onSearch" v-model="search">
                     <el-button
                       @click="onSearch"
                       slot="prepend"
                       icon="el-icon-search"
                     ></el-button>
                  </el-input>
                </el-col>
              </el-row>
            </div>
            <order-table :search='search' :orders="orders" :loading="loading" :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></order-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="Төлвүүдийн тайлбар" :visible.sync="dialogTableVisible">
      <el-table :data="statusData">
        <el-table-column label="Төлвүүд" prop="orderStatus" width="150"></el-table-column>
        <el-table-column label="Харагдах хэлбэр" prop="Appearance" width="200">
            <template slot-scope="scope">
              <div class="type">
                <p><el-tag type="success">{{ scope.row.Appearance }}</el-tag></p>
                <p><el-tag type="danger">{{ scope.row.Appearance1 }}</el-tag></p>
                <p v-if="scope.row.Appearance2"><el-tag type="warning">{{ scope.row.Appearance2 }}</el-tag></p>
                <p v-if="scope.row.Appearance3"><el-tag type="default">{{ scope.row.Appearance3 }}</el-tag></p>
              </div>
            </template>
        </el-table-column>
        <el-table-column label="Тайлбар" width="500">
          <template slot-scope="scope">
            <div  style="line-height: 2rem">
              <p>{{ scope.row.desc }}</p>
              <p>{{ scope.row.desc1 }}</p>
              <p>{{ scope.row.desc2 }}</p>
              <p>{{ scope.row.desc3 }}</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import OrderTable from './components/orderTable'
export default {
  name: 'orderList',
  components: {
    OrderTable
  },
  data () {
    return {
      statusData: [
        {
          orderStatus: 'Захиалгын төлөв',
          Appearance: 'Захиалсан',
          Appearance1: 'Цуцалсан',
          desc: 'Хэрэглэгч тухайн барааг захиалсан.',
          desc1: 'Хэрэглэгч захиалгаа цуцалсан.'
        },
        {
          orderStatus: 'Төлбөрийн төлөв',
          Appearance: 'Төлсөн',
          Appearance2: 'Хүлээгдэж байгаа',
          Appearance1: 'Хүчингүй',
          desc: 'Хэрэглэгч тухайн барааны төлбөрийн төлсөн.',
          desc1: 'Хэрэглэгч тухайн барааны төлбөрийг төлөөгүй байгаа.',
          desc2: 'Тухайн барааны захиалга цуцлагдсан тул төлбөр хүчингүй болсон.'
        },
        {
          orderStatus: 'Хүргэлтийн төлөв',
          Appearance2: 'Хүргэлтэнд гарсан',
          Appearance3: 'Харилцагч дээр ирсэн',
          Appearance1: 'Жолооч хувиарлаагүй',
          Appearance: 'Хүргэгдсэн',
          desc: 'Жолооч харилцагчаас барааг авч хүргэлтэнд гарсан.',
          desc1: 'Жолооч харилцагч дээр бараа авахаар ирсэн.',
          desc2: 'Уг барааг авах жолоочийг хувиарлаагүй байгаа.',
          desc3: 'Тухайн барааны захиалга амжилттай хэрэглэгчид хүргэгдсэн.'
        }
      ],
      dialogTableVisible: false,
      pay_status: this.$route.query.status ? this.$route.query.status : '',
      orders: [],
      loading: false,
      activeTabStatus: this.$route.query.tab ? this.$route.query.tab : 'all',
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      pageSize: 20,
      pageFrom: 0,
      search: '',
      totalCount: 0,
      from: '',
      size: '',
      tabName: this.$route.query.tab ? this.$route.query.tab : '',
      tabData: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Төлсөн',
          value: 'paid'
        }, {
          label: 'Төлөөгүй',
          value: 'notPaid'
        }
      ]
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getOrdersBySupplierId(size, from)
  },

  methods: {
    sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'orderList', query: { page: this.currentPage, size: size, tab: this.tabName, status: this.pay_status } })
      this.getOrdersBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    onSearch () {
      this.$router.push({ name: 'orderList', query: { page: this.currentPage, size: this.pageSize, search: this.search } }).catch(() => {})
      this.getOrdersBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },

    curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'orderList', query: { page: current, size: this.pageSize, tab: this.tabName, status: this.pay_status } })
      this.getOrdersBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },
    getOrdersBySupplierId (size, from) {
      this.loading = true
      const query = '?search_text=' + this.search + '&size=' + size + '&from=' + from + '&pay_status=' + this.pay_status
      services.getOrdersBySupplierId(query).then(response => {
        let tempData = 0
        this.loading = false
        if (response.data && response.status === 'success') {
          response.data.forEach(element => {
            element.OrderItems.forEach(el => {
              tempData = tempData + JSON.parse(el.menu_price) * JSON.parse(el.item_count)
            })
            element.menu_total = tempData
            tempData = 0
          })
          this.orders = response.data
          this.totalCount = response.total
        } else {
          this.alertReporter('Алдаа', response.message, 'error')
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'right-left'
      })
    },

    onClickTab (data) {
      this.currentPage = 1
      switch (data.label) {
        case 'Бүгд':
          this.pay_status = ''
          this.tabName = ''
          break
        case 'Төлсөн':
          this.pay_status = '1'
          this.tabName = 'paid'
          break
        case 'Төлөөгүй':
          this.pay_status = '0'
          this.tabName = 'notPaid'
          break
        default:
          this.pay_status = 'null'
      }
      this.$router.push({ name: 'orderList', query: { page: this.currentPage, size: this.pageSize, tab: this.tabName, status: this.pay_status } })
      this.getOrdersBySupplierId(this.pageSize, '0')
    }
  }
}
</script>

<style>
  .type .el-tag{
    width: 150px;
    text-align: center;
  }
</style>
